<template>
  <div>
    <v-row no-gutters v-if="fieldsLoading">
      <v-col v-for="n in 12" :key="n" cols="4">
       <v-sheet class="my-2 px-2">
        <v-skeleton-loader class="mx-auto" type="image" height="75"></v-skeleton-loader>
      </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data () {
    return {
      fieldsLoading: false
    }
  },
  created () {
    /* Get store host and check/set notification title/image */
    // const host = this.$store.state.common.host
    // let notificationTitle = ''
    // let notificationImage = ''
    // switch (process.env.VUE_APP_HOST_INCLUDES) {
    //   case 'axentiv':
    //     notificationTitle = 'AXENTIV'
    //     notificationImage = 'axentiv'
    //     break
    //   case 'weldit':
    //     notificationTitle = 'WELD IT'
    //     notificationImage = 'weldit'
    //     break
    //   case 'novap':
    //     notificationTitle = 'NOVAP'
    //     notificationImage = 'novap'
    //     break
    //   default:
    //     break
    // }
    /* if (host.includes(process.env.VUE_APP_HOST_INCLUDES)) {
      notificationTitle = 'AXENTIV'
      notificationImage = 'axentiv'
    } else {
      notificationTitle = 'WELD IT'
      notificationImage = 'weldit'
    } */

    this.fieldsLoading = true
    // getting host from URL and storing
    var date = new Date()
    date.setDate(date.getDate() + 4)
    const getOtp = this.$route.params.otp
    const getWelditToken = this.$route.query.token
    const checklistAccessToken = this.$route.query.checklist_access_token || ''
    const welditUser = this.$route.params.user_id
    this.$cookie.set(process.env.VUE_APP_HOST_USER, welditUser, { expires: date })
    this.$cookie.set(process.env.VUE_APP_HOST_TOKEN, getWelditToken, { expires: date })
    if (checklistAccessToken) this.$cookie.set(process.env.VUE_APP_CHECKLIST_ACCESS_TOKEN, checklistAccessToken, { expires: date })
    const getDomain = this.$route.params.domain ? `${this.$route.params.domain}${this.$store.state.common.host}` : ''
    var hostDate = new Date()
    hostDate.setDate(hostDate.getDate() + 30)
    this.$cookie.set('WelditPwa_host-ref', getDomain, { expires: hostDate })
    // getting lang from URL and storing
    const getLang = this.$route.params.lang ? this.$route.params.lang : 'no'
    this.$i18n.locale = getLang
    window.localStorage.setItem(process.env.VUE_APP_LOCALE_STORAGE, getLang)
    this.$api.execute('get', `auth/get_token_by_otp/${getOtp}`)
      .then((response) => {
        window.localStorage.removeItem('TimerPwa_weekno')
        window.localStorage.removeItem('TimerPwa_year')
        const authToken = response.data
        this.$api.defaults.headers.common.Authorization = `Bearer ${authToken}`
        this.$cookie.set(process.env.VUE_APP_TOKEN, authToken, { expires: date })
        this.$api.execute('get', 'users/get_current_user')
          .then((response) => {
            // eslint-disable-next-line
            const { id, name, email, role_id } = response.data
            const user = JSON.stringify({ id, name, email, role_id })
            this.$cookie.set(process.env.VUE_APP_USER, user, { expires: date })
            this.$store.commit('setAuth', { authToken: authToken, user: JSON.parse(user) })

            // general setting call and store project exclude variable
            this.$api.execute('get', 'generalsettings')
              .then((response) => {
                var timerStartup = 0
                if (response.data && response.data[0]) {
                  const model = response.data[0]
                  const isProjectExcluded = model.exclude_project_module !== undefined && model.exclude_project_module !== null ? model.exclude_project_module : false
                  const isDeviationExcluded = model.exclude_deviation_module !== undefined && model.exclude_deviation_module !== null ? model.exclude_deviation_module : false
                  this.$store.commit('isProjectExcluded', isProjectExcluded)
                  this.$store.commit('isDeviationExcluded', isDeviationExcluded)
                  const obj = { project: isProjectExcluded ? 1 : 0, deviation: isDeviationExcluded ? 1 : 0, expires_at: date }
                  this.$cookie.set('pwaAppExcludeModules', JSON.stringify(obj), { expires: date })
                  // set timer startup page value in cookie
                  timerStartup = response.data[0].timer_startup
                  this.$cookie.set('TimerPwa_timerStartup', timerStartup, { expires: date })
                }
                // const self = this
                /* Notification show part - count of not approved comments */
                if (('Notification' in window)) {
                  // Notification.requestPermission().then(function (result) {
                  //   if (result === 'granted') {
                  //     this.$api.execute('get', `hours/get_not_approved_entries_count/${id}`)
                  //       .then((res) => {
                  //         const weekDetails = res.data
                  //         let showWeekDetails = ''
                  //         if (weekDetails.length > 0) {
                  //           for (let i = 0; i < weekDetails.length; i++) {
                  //             if (weekDetails.length === (i + 1)) showWeekDetails += weekDetails[i]
                  //             else showWeekDetails += weekDetails[i] + ' , '
                  //           }
                  //           if ('serviceWorker' in navigator) {
                  //             navigator.serviceWorker.getRegistration().then(function (reg) {
                  //               var options = {
                  //                 body: self.$t('message.common.not_approved_notification', { count: weekDetails.length, weeknumber: showWeekDetails }),
                  //                 icon: require(`../../../public/img/${notificationImage}.png`)
                  //               }
                  //               if (reg) {
                  //                 reg.showNotification(notificationTitle, options)
                  //               }
                  //             })
                  //           }
                  //         }
                  //       })
                  //   }
                  // })
                }
              })
          }).finally(() => {
            this.fieldsLoading = false
          })
      })
  }
}
</script>
